import React from 'react'
import Layout from '~/components/Layout'
import Container from '~/components/Container'
import { Link } from 'gatsby'

import Seo from '~/components/Seo'

import * as classes from './404.module.scss'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className={classes.container404}>
      <Container>
        <h1 className={classes.title}>PAGE NOT FOUND</h1>
        <p>
          You just hit a page that doesn&#39;t exist.{' '}
          <Link to={`/`}>Back to homepage</Link>
        </p>
      </Container>
    </div>
  </Layout>
)

export default NotFoundPage
